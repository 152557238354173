import { graphql } from 'gatsby';
import React from 'react';
import { MediaMatcher, MediaServerRender } from 'react-media-match';
import BlueArrow from '../src/components/BlueArrow/BlueArrow';
import { Fulla } from '../src/components/Fulla/Fulla';
import Heimdall from '../src/components/Heimdall/Heimdall';
import Hod from '../src/components/Hod/Hod';
import Layout from '../src/components/Layout/Layout';
import NeoAesir from '../src/components/NeoAesir/NeoAesir';
import { Odin } from '../src/components/Odinv2/Odin';
import SEO from '../src/helpers/seo';

import './app.scss';

class App extends React.Component {
  state = {};

  render() {
    let fullaData;
    let fullaDataMobile;

    const heimdallData = {
      name: this.props.data.allPromocionesTitleBlockApp.edges[0].node.title,
      subtitle: this.props.data.allPromocionesTitleBlockApp.edges[0].node.subTitle,
      image: {
        url: this.props.data.allPromocionesBlockImageInfoGeneralApp.edges[0].node.localImage
          .childImageSharp.fluid,
        alt: this.props.data.allPromocionesBlockImageInfoGeneralApp.edges[0].node.alt,
      },
      imageMobile: {
        alt: this.props.data.allPromocionesMobileTitleBlockApp.edges[0].node.alt,
        url: this.props.data.allPromocionesMobileTitleBlockApp.edges[0].node.localImage
          .childImageSharp.fluid,
      },
    };

    const odinData = {
      image:
        this.props.data.allPromocionesEventBlockApp.edges[0].node.localImage.childImageSharp.fluid,
      alt: this.props.data.allPromocionesEventBlockApp.edges[0].node.alt,
      title: this.props.data.allPromocionesEventBlockApp.edges[0].node.event_name,
      rssImg: true,
      buttons: {
        cta1: this.props.data.allPromocionesEventBlockApp.edges[0].node.call_to_action_path,
        ctaText1: this.props.data.allPromocionesEventBlockApp.edges[0].node.call_to_action_copy,
        size: 'alone',
        color1: 'orange',
      },
    };

    fullaData = {
      titleText: this.props.data.allPromocionesContentMultiColumnBlockLeftApp
        ? this.props.data.allPromocionesContentMultiColumnBlockLeftApp.edges[0].node.title
        : null,
      fullaBody: this.props.data.allPromocionesContentMultiColumnBlockLeftApp
        ? this.props.data.allPromocionesContentMultiColumnBlockLeftApp.edges[0].node.body_left
        : null,
      secondaryTitleText: this.props.data.allPromosFullaBlockApp.edges[0].node.title,
      secondaryfullaBody: this.props.data.allPromosFullaBlockApp.edges[0].node.body,
    };

    fullaDataMobile = {
      titleText: this.props.data.allPromocionesContentMultiColumnBlockLeftApp
        ? this.props.data.allPromocionesContentMultiColumnBlockLeftApp.edges[0].node.title
        : null,
      titleType: 'H2',
      fullaBody: this.props.data.allPromocionesContentMultiColumnBlockLeftApp
        ? this.props.data.allPromocionesContentMultiColumnBlockLeftApp.edges[0].node.body_left
        : null,
      secondaryTitleText: this.props.data.allPromosFullaBlockApp.edges[0].node.title,
      secondaryfullaBody: this.props.data.allPromosFullaBlockApp.edges[0].node.body,
    };

    const isMobileOdin = (
      <>
        <Odin data={odinData} locale={this.props.pageContext.locale} />
        <Fulla context="tienda-online" data={fullaDataMobile} />
      </>
    );
    const isDesktopOdin = (
      <>
        <Odin data={odinData} locale={this.props.pageContext.locale} />
        <Fulla context="tienda-online" data={fullaData} />
      </>
    );

    return (
      <Layout
        layoutFromOld
        locale={this.props.pageContext.locale}
        pageName={this.props.pageContext.pageName}
        options={this.props.pageContext.options}
      >
        <SEO
          title={this.props.data.allPromocionesSeoDataApp.edges[0].node._0.title}
          description={this.props.data.allPromocionesSeoDataApp.edges[0].node._1.description}
          imageRich={this.props.data.allFichaPromosRichDataApp.edges[0].node.rich_markup.imgRich}
          pathname={this.props.pageContext.url}
          lang={this.props.pageContext.locale}
        />
        <Hod
          data={this.props.data.allFichaPromosRichDataApp.edges[0].node.rich_markup}
          url={this.props.pageContext.url}
          img={this.props.data.allFichaPromosRichDataApp.edges[0].node.imgRich}
          hardcoded={
            this.props.data.allAppSeoHardcoded.edges.length &&
            this.props.data.allAppSeoHardcoded.edges[0].node.richmark_text
          }
        />
        <div className="is-fullWidth">
          <Heimdall data={heimdallData} />
          <div className="general-index">
            <div className="app-content">
              <NeoAesir
                locale={this.props.pageContext.locale}
                pageName={this.props.pageContext.pageName}
                options={this.props.pageContext.options}
                title={this.props.data.allFichaPromosBreadCrumbBlockApp.edges[0].node.name}
              />

              <BlueArrow />
              <MediaServerRender predicted="mobile" hydrated>
                <MediaMatcher mobile={isMobileOdin} tablet={isMobileOdin} desktop={isDesktopOdin} />
              </MediaServerRender>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

App.propTypes = {};

export default App;

export const pageQuery = ({ data, pathContext: { locale } }) => graphql`
  query allFichaPromocionQueryApp($locale: String!) {
    allPromocionesTitleBlockApp(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          title
          subTitle
          description
        }
      }
    }
    allPromocionesSeoDataApp(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          _0 {
            title
          }
          _1 {
            description
          }
        }
      }
    }
    allAppSeoHardcoded(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          richmark_text
        }
      }
    }
    allPromocionesBlockImageInfoGeneralApp(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          alt
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    allPromocionesMobileTitleBlockApp(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          alt
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    allPromocionesEventBlockApp(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          localImage {
            childImageSharp {
              fluid(maxWidth: 1200, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          alt
          call_to_action_copy
          call_to_action_path
          event_name
        }
      }
    }
    allPromocionesContentMultiColumnBlockLeftApp(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          title
          body_left
        }
      }
    }
    allPromosFullaBlockApp(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          tag
          title
          body
        }
      }
    }
    allFichaPromosBreadCrumbBlockApp(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          destination_url
          name
        }
      }
    }
    allFichaPromosRichDataApp(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          rich_markup {
            color_css_class
            og_description
            og_title
            twitter_description
            twitter_title
            created_at
            updated_at
            rich_markup_type
            imgRich
            price
            date_valid_until
            date_valid_start
          }
        }
      }
    }
  }
`;
